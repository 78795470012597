<template>
  <div>
    <el-dialog title="离职办理" v-model="dimissionDialog" width="1000px" >
      <el-form style="height: 200px">
        <el-row style="display:flex;justify-content: space-around;">
          <el-col :span="8">
            <el-form-item prop="contractStartTime" label="申请时间" label-width="110px">
              <el-date-picker
                  v-model="dimissionApply.applyDate"
                  type="date"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择日期"
                  size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="contractEndTime" label="离职时间" label-width="110px">
              <el-date-picker
                  v-model="dimissionApply.dimissionDate"
                  type="date"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择日期"
                  size="large"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="display:flex;justify-content: space-around;">
          <el-col :span="18">
            <el-form-item prop="contractEndTime" label="原因说明" label-width="110px">
              <el-input
                  v-model="dimissionApply.dimissionCause"
                  :rows="4"
                  type="textarea"
                  placeholder="请说明离职原因！"
              />
            </el-form-item>

          </el-col>

        </el-row>
      </el-form>
      <template #footer>
        <el-button  @click="dimissionDialog = false">取消</el-button>
        <el-button type="primary"  @click="confirm">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {dimission} from "@/api/coachRoster";
export default {
  name: "DimissionApply",
  props: {
    item: String,
  },
  data(){
    return{
      dimissionDialog:false,
      CompanyList:[],
      dimissionApply:{
        applyDate:'',
        dimissionDate:'',
        dimissionCause:'',
        workId:null,
        coachId:null
      },
    }
  },
  methods:{
    openDialog(){
      this.dimissionDialog = true;
    },
    confirm(){
      this.dimissionApply.workId = this.item.workNumber;
      this.dimissionApply.coachId = this.item.coachId;
      this.dimissionApply.applyDate = this.dateFormat(this.dimissionApply.applyDate)
      this.dimissionApply.dimissionDate = this.dateFormat(this.dimissionApply.dimissionDate)
      dimission(this.dimissionApply).then(val => {
        if (val.code==200){
          this.$message.success("办理成功！");
          this.dimissionDialog = false;
        }else {
          this.$message.error("办理失败！");
        }
      })
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + m + "-" + d;
      return time;
    },
  },
  created(){

  }
}
</script>

<style scoped>

</style>