<template>
  <div>
    <div style="display: flex">
      <div style="width: 700px;padding: 0 30px;height: 580px">
        <el-popover v-if="item.coachList.length>1" placement="top" :width="220" trigger="hover">
          <div v-for="item in item.coachList" :key="item.id" >
            <div style="display:flex;justify-content: space-between;margin: 2px">
              <div style="width: 50%;">ID：{{item.coachid}}</div>
              <div style="width: 50%;">姓名：{{item.coachname}}</div>
            </div>
          </div>
          <template #reference>
            <div class="padding-tb" style="width: 50%">姓名：{{ item.coachList[0].coachname }} / {{ item.coachDetails?.sex == 0 ? '女':'男' }}</div>
          </template>
        </el-popover>
        <div class="padding-tb" style="width: 50%" v-else>姓名：{{ item.coachList[0].coachname }} / {{ item.coachDetails?.sex == 0 ? '女':'男' }}</div>
        <div class="padding-tb">民族：{{ item.coachDetails?.nationName }}</div>
        <div class="padding-tb">婚姻状态：{{ item.coachDetails?.isMarriage == 0 ? '未婚':'已婚' }}</div>
        <div class="padding-tb">生日：{{item.coachDetails?.birthdayType == 0 ? '阴历':'阳历'}} - {{ item.coachDetails?.birthday }}</div>
        <div style="display:flex;justify-content: space-between;">
          <div class="padding-tb">开户银行：{{ item.coachDetails?.bankName }}</div>
          <div  style="width: 50%" class="padding-tb">银行卡号：{{ item.coachDetails?.bankCardNumbers }}</div>
        </div>
        <div style="display:flex;justify-content: space-between;">
          <div>
            <div class="padding-tb">个人电话：{{ item.coachDetails?.personagePhone }}</div>
            <div class="padding-tb">个人邮箱：{{ item.coachDetails?.mailbox }}</div>
            <div class="padding-tb">身份证号：{{ item.coachDetails?.identity }}</div>
          </div>
          <div style="width: 50%">
            <div class="padding-tb">紧急联系人：{{ item.coachDetails?.emergencyContact }}</div>
            <div class="padding-tb">联系人关系：{{ item.coachDetails?.emergencyContactRelation }}</div>
            <div class="padding-tb">联系人电话：{{ item.coachDetails?.emergencyContactPhone }}</div>
          </div>
        </div>
        <div style="display:flex;justify-content: space-between;">
          <div class="padding-tb">现住地址：{{ item.coachDetails?.thisAddress }}</div>
          <div style="width: 50%" class="padding-tb">户籍地址：{{ item.coachDetails?.registeredResidenceSite }}</div>
        </div>
        <div class="padding-tb">现住详细地址：{{ item.coachDetails?.thisDetailedAddress }}</div>
        <div class="padding-tb">户籍详细地址：{{ item.coachDetails?.registeredResidenceDetailedAddress }}</div>
        <div class="padding-tb">个人简历：{{ item.coachDetails?.individualResume }}</div>




      </div>
      <div style="width: 700px;padding: 0 30px;text-align: left">
        <div class="padding-tb">工号：{{ item.workNumber }}</div>
        <div style="display:flex;justify-content: space-between;padding:0 20px 0 0 ">
          <el-popover v-if="item.coachList.length>1" placement="top" :width="260" trigger="hover">
            <div v-for="item in item.coachList" :key="item.id" >
              <div style="display:flex;justify-content: space-between;margin: 2px">
                <div style="width: 37%;">ID：{{item.coachid}}</div>
                <div style="width: 50%;">电话：{{item.deptName}}</div>
              </div>
            </div>
            <template #reference>
              <div class="padding-tb" style="width: 50%">所属部门：{{ item.coachList[0].deptName }}</div>
            </template>
          </el-popover>
          <div class="padding-tb" style="width: 50%" v-else>所属部门：{{ item.coachList[0].deptName }}</div>
        </div>
        <div class="padding-tb">员工状态：{{ item.staffTypeName }}</div>
        <div class="padding-tb">工作地点：{{ item.workplaceName }}</div>
        <div style="display:flex;justify-content: space-between;padding:0 20px 0 0 ">
          <div class="padding-tb" style="width: 50%">工龄：{{ item.yearsOfWorking }}</div>
          <div class="padding-tb" style="width: 50%">司龄：{{ item.companyAge }}</div>
        </div>
        <div style="display:flex;justify-content: space-between;padding:0 20px 0 0 ">
          <div class="padding-tb" style="width: 50%">首次工作日期：{{ item.theFirstWorkDate }}</div>
          <div class="padding-tb" style="width: 50%">入职日期：{{ item.entryDate }}</div>
        </div>
        <el-tabs v-model="activeName" class="demo-tabs" stretch="true" @tab-click="handleClick">

            <el-tab-pane label="学历列表" name="education">
              <el-scrollbar style="height: 300px">
                <div v-for="(education,index) in item.educationList" :key="index">
                <div class="record" style="background-color:  #e2effd;">
                  <div style="text-align: center;margin-bottom: 10px;">
                    <span style="font-size: 15px;font-weight: bold">{{education.schoolName}}</span> -
                    <span style="font-size: 12px;">{{education.educationRankIdName}}</span>
                  </div>
                  <div style="display:flex;justify-content: space-between;">
                    <div>专业：{{education.major}}</div>
                    <div>毕业时间：{{education.graduateTime}}</div>
                    <div>学历类型：{{education.isFullTimeName}}</div>
                  </div>
                </div>
              </div>
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane label="合同列表" name="contract">
              <el-scrollbar style="height: 300px">
                <div class="record" :style="contract.contractNumType == 1 ? 'background-color: #f0f9eb ':'background-color:#f4f4f5'"
                   v-for="contract in item.contractList" :key="contract.id" >
                <div style="text-align: center;margin-bottom: 10px;font-size: 15px;font-weight: bold">{{contract.signContractFirstPartyName}}</div>

                <div class="record-date" style="margin-top: 4px">
                  <div>合同类型：{{contract.contractTypeName}}</div>
                  <div>有效日期：{{contract.contractStartTime}} - {{contract.contractEndTime}}</div>
                </div>
              </div>
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane label="转正记录" name="official">
              <el-scrollbar style="height: 300px">
                <div v-for="officials in item.officialList" :key="officials.id" class="record" style="background-color: #f0f9eb">
                <div  class="record-date" style="padding: 0 10px">
                  <div>申请时间：{{officials.applyDate}}</div>
                  <div>转正时间：{{officials.officialDate}}</div>
                </div>
              </div>
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane label="离职记录" name="dimission">
              <el-scrollbar style="height: 300px">
                <div v-for="dimission in item.dimissionList" :key="dimission.id" class="record" style="background-color:  #fef0f0">
                <div  style="padding: 0 10px">
                  <div class="record-date">
                    <div>申请时间：{{dimission.applyDate}}</div>
                    <div>离职时间：{{dimission.dimissionDate}}</div>
                  </div>
                  <div>离职原因：{{dimission.dimissionCause}}</div>
                </div>
              </div>
              </el-scrollbar>
            </el-tab-pane>

        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {selectCoachContractByCoachId} from '@/api/coach.js'
export default {
  name: "CoachDetail",
  props: {
    item: String,
  },
  data(){ 
    return{
      activeName:"education",
      listDialog:false,
      List:[]


    }
  },
  methods:{
    searchContarct(){ 
      selectCoachContractByCoachId(this.item.coachId).then(res=>{ 
        if(res.code == 200){ 
          this.listDialog = true
          this.List = res.data
        }else{ 
          this.$message.error('获取合同列表失败！')
        }
      })
    }
  }
}
</script>

<style scoped>
.padding-tb{
  padding:5px 0;
}
.record{
  margin: 10px 0;
  padding: 16px 20px;
  border-radius: 10px
}
.record-title{
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px
}
.record-date{
  display:flex;
  justify-content: space-between;
}
</style>