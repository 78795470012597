<template>
  <div>
    <el-dialog title="转正办理" v-model="officialDialog" width="1000px" >
      <el-form style="height: 200px">
        <el-row style="display:flex;justify-content: space-around;">
          <el-col :span="8">
            <el-form-item prop="contractStartTime" label="申请时间" label-width="110px">
              <el-date-picker
                  v-model="officialApply.applyDate"
                  type="date"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择日期"
                  size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="contractEndTime" label="转正时间" label-width="110px">
              <el-date-picker
                  v-model="officialApply.officialDate"
                  type="date"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择日期"
                  size="large"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="display:flex;justify-content: space-around;">
          <el-col :span="18">
            <el-form-item prop="contractEndTime" label="备注" label-width="110px">
              <el-input
                  v-model="officialApply.note"
                  :rows="4"
                  type="textarea"
                  placeholder="请输入"
              />
            </el-form-item>

          </el-col>

        </el-row>
      </el-form>
      <template #footer>
        <el-button  @click="contractDialog = false">取消</el-button>
        <el-button type="primary"  @click="confirm">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {official} from "@/api/coachRoster";
export default {
  name: "OfficialApply",
  props: {
    item: String,
  },
  data(){
    return{
      officialDialog:false,
      CompanyList:[],
      officialApply:{
        applyDate:'',
        officialDate:'',
        workId:null,
        coachId:null,
        note:null
      },
    }
  },
  methods:{
    openDialog(){
      this.officialDialog = true;
    },
    confirm(){
      this.officialApply.workId = this.item.workNumber;
      this.officialApply.coachId = this.item.coachId;
      this.officialApply.applyDate = this.dateFormat(this.officialApply.applyDate)
      this.officialApply.officialDate = this.dateFormat(this.officialApply.officialDate)
      console.log(this.item)
      console.log(this.officialApply)
      official(this.officialApply).then(val => {
        if (val.code==200){
          this.$message.success("办理成功！");
          this.officialDialog = false;
        }else {
          this.$message.error("办理失败！");
        }
      })
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + m + "-" + d;
      return time;
    },
  },
  created(){

  }
}
</script>

<style scoped>

</style>