<template>
  <div style="padding: 20px">
    <div style="display:flex;flex-wrap: wrap;margin-bottom: 30px">
        <el-input style="width: 200px" v-model="query.coachInfo" placeholder="请输入姓名、电话、工号" class="margin-right"></el-input>
        <div class="margin10">
          <select-area size="" @areaListId="getAreaListId"/>
        </div>
        <el-select
                   style="width: 160px"
                   class="margin-right"
                   multiple placeholder="所属岗位"
                   v-model="query.station"
                   collapse-tags
                   collapse-tags-tooltip
                   clearable>
          <el-option v-for="item in station" :value="item.dictValue" :key="item.dictValue"  :label="item.dictLabel"></el-option>
        </el-select>
        <el-select
                   style="width: 160px"
                   class="margin-right"
                   multiple
                   placeholder="部门"
                   v-model="query.dept"
                   collapse-tags
                   collapse-tags-tooltip
                   clearable>
          <el-option v-for="item in dept" :value="item.id" :key="item.id" :label="item.name"></el-option>
        </el-select>
        <el-select style="width: 100px" class="margin-right" v-model="query.dateType">
          <el-option v-for="item in dateTypeList" :value="item.id" :key="item.id"  :label="item.name"></el-option>
        </el-select>
        <div class="margin-right">
          <el-date-picker
              style="width: 240px;"
              v-model="temporaryDate"
              type="daterange"
              range-separator="至"
              @change="disposeTime"
              :start-placeholder="dateTypeList[query.dateType].name"
              :end-placeholder="dateTypeList[query.dateType].name"
              clearable>
          </el-date-picker>
        </div>
        <el-button type="primary" icon="Search" @click="selectRosterList()" class="margin-right">搜索</el-button>
      </div>
    <el-table
        v-loading="tableLogin"
        :data="rosterList"
        height="630px"
        :row-style="{height: '58px'}"
        border
        style="width: 100%"
    >
      <el-table-column label="ID ~ 工号" width="180" align="center" show-overflow-tooltip prop="coachId">
        <template #default="scope">
            <span>{{ scope.row.coachId }}</span>
            <span style="margin: 0 10px">~</span>
            <span style="width: 90px;text-align: left;display: inline-block;">{{ scope.row.workNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="教练姓名 / 性别" width="140" align="center" show-overflow-tooltip>
        <template #default="scope">
          <el-popover v-if="scope.row.coachList.length>1" placement="top" :width="220" trigger="hover">
            <div v-for="item in scope.row.coachList" :key="item.id" >
              <div style="display:flex;justify-content: space-between;margin: 2px">
                <div style="width: 50%;">ID：{{item.coachid}}</div>
                <div style="width: 50%;">姓名：{{item.coachname}}</div>
              </div>
            </div>
            <template #reference>
              <span>{{ scope.row.coachList[0].coachname }}</span>
            </template>
          </el-popover>
          <span v-else>{{ scope.row.coachList[0].coachname }}</span>
          <span style="margin: 0 6px">/</span>
          <span style="color: #ff7b8b" v-if="scope.row?.coachDetails?.sex == 0">女</span>
          <span style="color: #58a3e9" v-if="scope.row?.coachDetails?.sex == 1">男</span>
          <span v-if="scope.row?.coachDetails?.sex != 1 && scope.row?.coachDetails?.sex != 0">*</span>
        </template>
      </el-table-column>
      <el-table-column label="教练电话" width="120" align="center" show-overflow-tooltip>
        <template #default="scope">
          <el-popover v-if="scope.row.coachList.length>1" placement="top" :width="260" trigger="hover">
            <div v-for="item in scope.row.coachList" :key="item.id" >
              <div style="display:flex;justify-content: space-between;margin: 2px">
                <div style="width: 37%;">ID：{{item.coachid}}</div>
                <div style="width: 50%;">电话：{{item.coachphone}}</div>
              </div>
            </div>
            <template #reference>
              <span>{{ scope.row.coachList[0].coachphone }}</span>
            </template>
          </el-popover>
          <span v-else>{{ scope.row.coachList[0].coachphone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="个人电话" width="130" align="center" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ scope.row?.coachDetails?.personagePhone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="生日" width="150" align="center" show-overflow-tooltip>
        <template #default="scope">
          <span v-if="scope.row?.coachDetails?.birthdayType == 0">阴历 / </span>
          <span v-if="scope.row?.coachDetails?.birthdayType == 1">阳历 / </span>
          <span v-if="scope.row?.coachDetails?.isMarriage != 1 && scope.row?.coachDetails?.isMarriage != 0">*</span>
          <span>  {{ scope.row?.coachDetails?.birthday }}</span>
        </template>
      </el-table-column>
      <el-table-column label="婚姻状况" width="90" align="center" show-overflow-tooltip>
        <template #default="scope">
          <span v-if="scope.row?.coachDetails?.isMarriage == 0">未婚</span>
          <span style="color: #67C23A" v-if="scope.row?.coachDetails?.isMarriage == 1">已婚</span>
          <span v-if="scope.row?.coachDetails?.isMarriage != 1 && scope.row?.coachDetails?.isMarriage != 0">*</span>
        </template>
      </el-table-column>
      <el-table-column label="个人邮箱" width="180" align="center" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ scope.row?.coachDetails?.mailbox }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属部门" width="120" align="center" show-overflow-tooltip>
        <template #default="scope">
          <el-popover v-if="scope.row.coachList.length>1" placement="top" :width="260" trigger="hover">
            <div v-for="item in scope.row.coachList" :key="item.id" >
              <div style="display:flex;justify-content: space-between;margin: 2px">
                <div style="width: 37%;">ID：{{item.coachid}}</div>
                <div style="width: 50%;">电话：{{item.deptName}}</div>
              </div>
            </div>
            <template #reference>
              <span>{{ scope.row.coachList[0].deptName }}</span>
            </template>
          </el-popover>
          <span v-else>{{ scope.row.coachList[0].deptName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属岗位" width="130" align="center" show-overflow-tooltip prop="positionName"/>
      <el-table-column label="员工状态" width="100" align="center" show-overflow-tooltip prop="staffTypeName"/>
      <el-table-column label="入职时间" width="160" align="center" show-overflow-tooltip prop="entryDate"/>
      <el-table-column label="转正时间" width="100" align="center" show-overflow-tooltip>
        <template #default="scope">
          <span v-if="scope.row.officialList.length>0">{{ scope.row.officialList[0].officialDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" fixed="right" align="center">
        <template #default="scope">
          <el-button @click="detailDialog=true,detailItem = scope.row" style="width: 14px;" size="small" type="text">详情</el-button>
          <el-popover placement="left" :width="251" trigger="click">
            <el-divider style="">工具</el-divider>
            <div class="space-around" style="margin: 5px 0 10px 0">
              <el-button type="text" size="small" @click="contractExtension('updateRoster'),detailItem = scope.row">编辑</el-button>
            </div>
            <div class="space-around" style="margin: 5px 0 10px 0">
              <el-button type="text" size="small" @click="contractExtension('contract'),detailItem = scope.row">续签合同</el-button>
              <el-button type="text" size="small" @click="contractExtension('official'),detailItem = scope.row">转正办理</el-button>
              <el-button type="text" size="small" style="color: red" @click="contractExtension('dimission'),detailItem = scope.row">离职办理</el-button>
            </div>
            <template #reference>
              <el-button type="text" style="width: 14px;"  size="small">操作</el-button>
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>


    <el-dialog title="教练个人信息" v-model="detailDialog" width="1300px">
      <roster-detail :item="detailItem"/>
    </el-dialog>

<!--    合同续签子组件-->
    <contract-extension ref="contract" :item="detailItem"/>
    <official-apply ref="official" :item="detailItem"/>
    <dimission-apply ref="dimission" :item="detailItem"/>
    <roster-update ref="updateRoster" @selectRoster="selectRosterList" :edit-item="detailItem"/>
  </div>
</template>
<script>
import {selectDictByType} from '@/api/dict'
import {selectDeptList} from '@/api/dept'
import {selectRosterList} from "@/api/coachRoster";
import selectArea from "../../../components/SelectArea";
import rosterDetail from "./components/RosterDetail";
import contractExtension from "./components/ContractExtension";
import officialApply from "./components/OfficialApply";
import dimissionApply from "./components/DimissionApply";
import rosterUpdate from "./components/RosterUpdate";
import {
  // exportCoachRosterExcel,
  exportCoach
} from "@/api/coach";
export default {
  components:{
    selectArea,rosterDetail,contractExtension,officialApply,dimissionApply,rosterUpdate
  },
  name: "Roster",
  data() {
    return {
      contractDialog:false,
      detailDialog:false,
      detailItem:{},
      tableLogin:false,
      rosterList:[],
      size:"small",
      temporaryDate:[],
      station:[],
      dept:[],
      dateTypeList:[{name:"入职时间",id:0},{name:"离职时间",id:1},{name:"转正时间",id:2}],
      pageTotal:0,

      query: {
        coachInfo: "",
        station: [],
        dept:[],
        areaList:[],
        dateType: 0,
        state:"",
        pageIndex:0,
        pageSize:10,
        sectionStartDate:null,
        sectionEndDate:null
      }
    }
  },
  methods:{
    //导出花名册
    exportCoachRoster(){
      exportCoach(this.query)
    },
    /**
     * 搜索选择区域
     */
    getAreaListId(val){
      this.query.areaList = val;
    },
    /**
     * 调用子组件方法打开弹出层
     */
    contractExtension(dialog){
      if (dialog == 'contract'){
        this.$refs.contract.openDialog();
      }else if (dialog == 'official') {
        this.$refs.official.openDialog();
      }else if (dialog == 'dimission') {
        this.$refs.dimission.openDialog();
      }else  if (dialog == 'updateRoster') {
        this.$refs.updateRoster.openDialog();
      }
    },

    handlePageChange(val){
      this.query.pageIndex = val;
      this.selectRosterList();
    },
    /**
     * 查询全部部门
     */
    selectRosterList(){
      this.tableLogin = true;
      // console.log(this.temporaryDate)
      if (this.temporaryDate!=null && this.temporaryDate.length>0){
        this.query.sectionStartDate = this.dateFormat(this.temporaryDate[0]);
        this.query.sectionEndDate = this.dateFormat(this.temporaryDate[1]);
      }else {
        this.query.sectionStartDate = null;
        this.query.sectionEndDate = null;
      }

      selectRosterList(this.query).then(res=>{
        this.tableLogin = false;
        if(res.code == 200){
          this.rosterList = res.data.records
          this.pageTotal = res.data.total
        }
      })
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + m + "-" + d;
      return time;
    },
  },
  created(){
    this.selectRosterList();
    /**
     * 查询全部岗位
     */
    selectDictByType('station').then(res=>{
      this.station = res.data
    })
    /**
     * 查询全部部门
     */
    selectDeptList().then(res=>{
      if(res.code == 200){
        this.dept = res.data
      }
    })
  },

}
</script>

<style scoped>
.margin-right{
  margin-right: 10px;
}
.space-around {
  display: flex;
  justify-content: space-around;
}
</style>