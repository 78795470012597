<template>
  <div>
    <el-dialog :title="'工号` '+updateItem.workNumber+' `的个人信息'" v-model="updateDialog" width="1300px">
      <el-scrollbar style="height: 540px">
            <el-form v-loading="editLogin" ref="secondAddRules"  :model="updateItem.coachDetails" style="padding: 20px 40px 20px 20px" label-width="90px">
              <el-timeline>
                <el-timeline-item timestamp="" placement="top">
                  <el-card shadow="hover" style="width: 99%;">
                    <h4 style="margin-bottom: 20px;color: #409EFF">个人信息</h4>
                    <el-row>
                      <el-col :span="6">
                        <el-form-item prop="sex" label-width="90px" label="性别">
                          <el-select v-model="updateItem.coachDetails.sex">
                            <el-option label="男" :value="1"></el-option>
                            <el-option label="女" :value="0"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="nation" label-width="100px" label="民族">
                          <el-select size="large" v-model="updateItem.coachDetails.nation" placeholder="请选择民族" >
                            <el-option v-for="item in nationList" :value="item.dictValue*1 " :key="item.id" :label="item.dictLabel"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="nation" label-width="100px" label="所属部门">
                          <el-select :size="size"
                                    style="width: 177px"
                                    class="margin-right"
                                    placeholder="部门"
                                    v-model="updateItem.coachList[0].deptId" >
                            <el-option v-for="item in dept" :value="item.id" :key="item.id" :label="item.name"></el-option>
                          </el-select>
                          <!-- <el-cascader
                            v-model="updateItem.coachList[0].deptId"
                            style="width: 177px"
                            placeholder="请选择部门"
                            :props="{
                            value: 'id',
                            label: 'name',
                            children: 'children',
                            checkStrictly: true}"
                            :options="deptList"/> -->
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="nation" label-width="100px" label="岗位">
                          <el-select size="large" v-model="updateItem.positionId" placeholder="请选择岗位" >
                            <el-option v-for="item in stationList" :value="item.dictValue*1 " :key="item.id" :label="item.dictLabel"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="6">
                        <el-form-item prop="personagePhone" label-width="90px" label="个人电话">
                          <el-input v-model="updateItem.coachDetails.personagePhone" placeholder="请输入个人电话"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="isMarriage" label-width="100px" label="婚姻状态">
                          <el-select size="large" class="mr10" v-model="updateItem.coachDetails.isMarriage" placeholder="请选择婚姻状态" >
                            <el-option label="已婚" :value="1"></el-option>
                            <el-option label="未婚" :value="0"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="birthdayType" label-width="100px" label="生日类型">
                          <el-select  size="large" class="mr10" v-model="updateItem.coachDetails.birthdayType" placeholder="请选择生日类型" >
                            <el-option label="阴历" :value="0"></el-option>
                            <el-option label="阳历" :value="1"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="birthday" label-width="100px" label="选择生日">
                          <el-date-picker
                              @blur="getBirthDate('birthday')"
                              style="width:176px"
                              v-model="updateItem.coachDetails.birthday"
                              type="date"
                              format="YYYY/MM/DD"
                              value-format="YYYY-MM-DD"
                              placeholder="Pick a day"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="6">
                        <el-form-item prop="mailbox" label-width="90px" label="个人邮箱">
                          <el-input v-model="updateItem.coachDetails.mailbox" placeholder="请输入个人邮箱"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="identity" label-width="100px" label="身份证号">
                          <el-input v-model="updateItem.coachDetails.identity" placeholder="请填写身份证号"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="bankName" label-width="100px" label="开户银行">
                          <el-input  v-model="updateItem.coachDetails.bankName" placeholder="请输入开户银行"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="bankCardNumbers" label-width="100px" label="银行卡号">
                          <el-input  v-model="updateItem.coachDetails.bankCardNumbers" placeholder="请输入银行卡号"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col>
                        <el-form-item prop="individualResume" label-width="90px" label="个人简历">
                          <el-input
                              type="textarea"
                              placeholder="请填写个人简历"
                              :rows="4"
                              v-model="updateItem.coachDetails.individualResume"/>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-timeline-item>
                <el-timeline-item timestamp="" placement="top">
                  <el-card shadow="hover" style="width: 99%;">
                    <h4 style="margin-bottom: 20px;color: #409EFF">紧急联系人</h4>
                    <el-row>
                      <el-col :span="6">
                        <el-form-item prop="emergencyContact" label="姓名" label-width="90px">
                          <el-input v-model="updateItem.coachDetails.emergencyContact" placeholder="请填写紧急联系人"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="emergencyContactRelation" label="关系" label-width="90px">
                          <el-input v-model="updateItem.coachDetails.emergencyContactRelation" placeholder="请填写关系"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item prop="emergencyContactPhone" label-width="90px" label="电话">
                          <el-input v-model="updateItem.coachDetails.emergencyContactPhone" placeholder="请填写电话"></el-input>
                        </el-form-item>
                      </el-col>

                    </el-row>
                  </el-card>
                </el-timeline-item>
                <el-timeline-item timestamp="" placement="top">
                  <el-card shadow="hover" style="width: 99%;">
                    <h4 style="margin-bottom: 20px;color: #409EFF">住址信息</h4>
                    <el-row>
                      <el-col :span="8">
                        <el-form-item prop="thisAddress" label="现住地址" >
                          <el-cascader
                              size="large"
                              :options="pcaTextArr"
                              v-model="updateItem.coachDetails.thisAddress">
                          </el-cascader>
                        </el-form-item>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="thisDetailedAddress" label="现住详细地址" label-width="110px">
                          <el-input v-model="updateItem.coachDetails.thisDetailedAddress"></el-input>
                        </el-form-item>
                      </el-col>

                    </el-row>
                    <el-row>
                      <el-col :span="8">
                        <el-form-item prop="registeredResidenceSite" label="户籍地址">
                          <el-cascader
                              size="large"
                              :options="pcaTextArr"
                              v-model="updateItem.coachDetails.registeredResidenceSite">
                          </el-cascader>
                        </el-form-item>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="registeredResidenceDetailedAddress" label="户籍详细地址" label-width="110px">
                          <el-input v-model="updateItem.coachDetails.registeredResidenceDetailedAddress"></el-input>
                        </el-form-item>
                      </el-col>

                    </el-row>
                  </el-card>
                </el-timeline-item>
                <el-timeline-item timestamp="" placement="top">
                  <el-card shadow="hover" style="width: 99%;">
                    <h4 style="margin-bottom: 20px;color: #409EFF">其他信息</h4>
                    <el-row>
                      <el-col :span="15">
                        <el-form-item prop="workplace" label-width="110px" label="工作地点">
                          <el-select style="width: 544px" v-model="updateItem.workplace">
                            <el-option v-for="item in workplaceList" :value="item.dictValue*1" :key="item.id" :label="item.dictLabel"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="7">
                        <el-form-item prop="theFirstWorkDate" label-width="110px" label="实习日期">
                          <el-date-picker
                              @blur="getBirthDate('theFirstWorkDate')"
                              style="width:218px"
                              placeholder="请选择"
                              v-model="updateItem.theFirstWorkDate"
                              type="date"
                              format="YYYY/MM/DD"
                              value-format="YYYY-MM-DD"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="7">
                        <el-form-item prop="entryDate" label-width="110px"  label="入职时间" >
                          <el-date-picker
                              style="width:218px"
                              @blur="getBirthDate('entryDate')"
                              v-model="updateItem.entryDate"
                              type="date"
                              format="YYYY/MM/DD"
                              value-format="YYYY-MM-DD"
                              placeholder="请选择"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="7">
                        <el-form-item prop="businessType" label-width="110px"  label="业务类型">
                          <el-select size="large"  v-model="updateItem.businessType" class="mr10" placeholder="请选择业务类型">
                            <el-option v-for="item in businessTypeList" :value="item.dictValue*1" :key="item.id" :label="item.dictLabel"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="7">
                        <el-form-item label="转正日期"  label-width="110px">
                          <el-date-picker
                              style="width:218px"
                              @blur="getBirthDate('officialList')"
                              v-model="officialDate"
                              type="date"
                              format="YYYY/MM/DD"
                              value-format="YYYY-MM-DD"
                              placeholder="请选择"
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="7">
                        <el-form-item label="离职日期" label-width="110px">
                          <el-date-picker
                              style="width:218px"
                              @blur="getBirthDate('dimissionList')"
                              v-model="dimissionDate"
                              type="date"
                              format="YYYY/MM/DD"
                              value-format="YYYY-MM-DD"
                              placeholder="请选择"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-timeline-item>
                <el-form v-for="(item,index) in updateItem.educationList" ref="thirdAddRules" :key="index" :model="item">
                  <el-timeline-item timestamp="" placement="top">
                    <el-card shadow="hover" style="width: 99%;border-radius: 30px">
                      <h4 style="margin-bottom: 20px;color: #409EFF">{{smallTurnMax(index)}}学历</h4>
                      <el-row>
                        <el-col :span="7">
                          <el-form-item prop="schoolName"  label-width="110px" label="学校名称">
                            <el-input v-model="item.schoolName" placeholder="请填写学校名称"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="7">
                          <el-form-item prop="major" label-width="110px" label="专业">
                            <el-input v-model="item.major" placeholder="请填专业名称"></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6"></el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="7">
                          <el-form-item prop="educationRankId" label-width="110px"  label="学历等级">
                            <el-select v-model="item.educationRankId" placeholder="请选择学历">
                              <el-option v-for="items in educationRankList" :value="items.dictValue*1 " :key="items.id" :label="items.dictLabel"></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="7">
                          <el-form-item prop="isFullTime" label-width="110px" label="学历类型">
                            <el-select v-model="item.isFullTime" placeholder="请选择">
                              <el-option label="全日制" :value="0"></el-option>
                              <el-option label="非全日制" :value="1"></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6">
                          <el-form-item prop="graduateTime"  label-width="110px" label="毕业时间">
                            <el-date-picker
                                v-model="item.graduateTime"
                                @blur="getBirthDate('graduateTime',index)"
                                type="date"
                                format="YYYY/MM/DD"
                                value-format="YYYY-MM-DD"
                                placeholder="请选择"
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-card>
                  </el-timeline-item>
                </el-form >
              </el-timeline>
            </el-form>
      </el-scrollbar>
    <template #footer>
      <el-button  @click="dimissionDialog = false">取消</el-button>
      <el-button type="primary"  @click="confirm">确定</el-button>
    </template>
    </el-dialog>
  </div>
</template>
<script>
import { pcaTextArr } from 'element-china-area-data'
import {listAreaTreeAddCoach} from "@/api/area.js";
import {updateRoster} from "@/api/coachRoster";
import {
  // listXcxDept,
  selectDeptList} from "@/api/dept";
import {selectDictByType} from '@/api/dict'
import {
   roles,
   createWorkNumber,
  } from "@/api/coach";
export default{ 
  props:{
    editItem:Object
  },
  inject:['editContract'],
  data(){ 
    return{
      updateDialog:false,
      officialDate:'',//转正时间
      dimissionDate:'',//离职时间
      pcaTextArr,
      orderType:0,
      activeName:'second',
      sysStudentType:localStorage.getItem('studentType'),
      updateItem:{
        coachDimissionApply:{ 
        dimissionDate:'',
        dimissionCause:''
        },
        coachOfficialApply:{ 
        applyDate:'',
        officialDate:''
      },
      },
      contract:{
        contractEndTime:'',
        contractStartTime:'',
        contractType:'',
        signContractFirstParty:'',
        coachId:null,
      },
      treeList:[],
      deptList:[],
      roles:[],
      dept:[],
      stationList:[],
      staffTypeList:[],
      CompanyList:[],
      nationList:[],
      businessTypeList:[],
      workplaceList:[],
      contractTypeList:[],
      educationRankList:[],
      firstAddRules: {
        coachName: [
          {required: true, message: '请输入教练名字', trigger: 'blur'},
        ],
        coachPhone: [
          {required: true, message: '请输入教练电话', trigger: 'blur'},
        ],
        campusId: [
          {required: true, message: '请选择所属校区', trigger: 'blur'},
        ],
        areaList: [
          {required: true, message: '请选择可视区域', trigger: 'change'},
        ],
        roles: [
          {required: true, message: '请选择教练角色', trigger: 'change'}
        ],
        deptId: [
          {required: true, message: '请选择所属部门', trigger: 'change'}
        ],
      },
    }
  },
  created(){   
   this.getList()
  },
  methods:{
    smallTurnMax(number){
      if (number == 0){
        return "一、"
      }else if (number == 1){
        return "二、"
      }else if (number == 2){
        return "三、"
      }else if (number == 3){
        return "四、"
      }else if (number == 4){
        return "五、"
      }
    },
    openDialog(){
      this.updateDialog = true;
    },
    addWorkNumber(){
      createWorkNumber().then(res=>{ 
       if(res.code == 200){ 
          this.updateItem.workNumber = res.data
       }else{ 
          this.$message.error('生成工号失败！')
       }
      })
    },
    changeStudentType(){ 
      this.updateItem.campusId = null
      listAreaTreeAddCoach(this.orderType).then(val => {
        if (val.code==200){
          this.getTypeList(val.data);
          this.treeList = val.data;     
        }
      })
    },
    getBirthDate(e,index){ 
        switch (e) { 
          case 'theFirstWorkDate':{
            let time = this.updateItem.theFirstWorkDate
            time = this.dateFormat(time)
            this.updateItem.theFirstWorkDate = time
          break;
          }
          case 'entryDate':{
            let time1 = this.updateItem.entryDate
            time1 = this.dateFormat(time1)
            this.updateItem.entryDate = time1
          break;
          }
          case 'contractStartTime':{
            let time2 = this.updateItem.contractStartTime
            time2 = this.dateFormat(time2)
            this.updateItem.contractStartTime = time2
          break;
          }
          case 'officialList':{
            let time3 = this.officialDate
            time3 = this.dateFormat(time3)
            if(this.updateItem.officialList.length == 0){ 
              let obj = { 
                officialList:time3
              }
              this.updateItem.officialList.push(obj)
            }else{ 
                this.updateItem.officialList[0].officialDate = time3
            }  
          break;
          }
          case 'dimissionList':{
            let time4 = this.dimissionDate
            time4 = this.dateFormat(time4)
            if(this.updateItem.dimissionList.length == 0){ 
              let obj = {
                  dimissionDate : time4
              }
              this.updateItem.dimissionList.push(obj)
            }else{ 
              this.updateItem.dimissionList[0].dimissionDate = time4
            }     
          break;
          }
          case 'contractEndTime':{
            let time3 = this.updateItem.contractEndTime
            time3 = this.dateFormat(time3)
            this.updateItem.contractEndTime = time3
          break;
          }
          case 'birthday':{
            console.log(this.updateItem)
            let time5 = this.updateItem.coachDetails.birthday
            time5 = this.dateFormat(time5)
            this.updateItem.coachDetails.birthday = time5
          break;
          }
          default:{
            let time4 = this.updateItem.educationList[index].graduateTime
            time4 = this.dateFormat(time4)
            this.updateItem.educationList[index].graduateTime = time4
          break;
            }
          }
    },
    dateFormat(val) {
        var date = new Date(val);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        d = d < 10 ? "0" + d : d; 
        const time = parseInt(y) + "-" + m + "-" + d;
        return time;
    },
    getTypeList(commodityType) {
        if (commodityType!=null){
          commodityType.forEach(items => {
            if (items!=null){
              if (items.children!=null && items.children.length > 0) {
                this.getTypeList(items.children);
              } else {
                items.children = undefined;
              }
            }
          });
          return commodityType;
        }
    },
    confirm(){
      updateRoster(this.updateItem).then(val=>{
        if (val.code==200){
          this.$message.success("更新成功！");
          this.updateDialog = false;
          this.$emit('selectRoster');
        }else {
          this.$message.error("更新失败！");
        }
      })
    },
   async getList(){
     selectDictByType('signContractFirstParty').then(res=>{
       this.CompanyList = res.data
     })
      listAreaTreeAddCoach().then(val => {
        if (val.code==200){
          this.getTypeList(val.data);
          this.treeList = val.data;     
        }
      })
      // listXcxDept().then(res => {
      //   this.deptList = this.getTypeList(res.data);    
      // })
      roles().then(res => {
          if (res.code == 200) {
            this.roles = res.data
          } else {
            this.$message.error("获取角色数据失败");
          }
      })
      //业务类型
      selectDictByType('businessType').then(res=>{ 
          this.businessTypeList = res.data
      })
      //民族
      selectDictByType('nation').then(res=>{ 
          this.nationList = res.data
      })
      //工作地点
      selectDictByType('workplace').then(res=>{ 
          this.workplaceList = res.data
      })
      selectDictByType('educationRank').then(res=>{ 
          this.educationRankList = res.data
      })
      selectDictByType('staffType').then(res=>{ 
          this.staffTypeList = res.data
      })
      selectDictByType('contractType').then(res=>{ 
          this.contractTypeList = res.data
      })
      //岗位
      selectDictByType('station').then(res=>{ 
          this.stationList = res.data
      })
      selectDeptList().then(res=>{
      if(res.code == 200){
        this.dept = res.data
      }
    })
    }
  },
  watch:{
    editItem:{
      handler:function(newVal){
        this.updateItem = JSON.parse(JSON.stringify(newVal))
        console.log(this.updateItem);
        if(this.updateItem.officialList && this.updateItem.officialList.length>0){ 
          this.officialDate = this.updateItem.officialList[0].officialDate
        }
        if(this.updateItem.dimissionList && this.updateItem.dimissionList.length>0){ 
              this.dimissionDate = this.updateItem.dimissionList[0].dimissionDate
        }
        if(!this.updateItem.educationList){
          this.updateItem.educationList = [
            {
              educationRankId:null,
              graduateTime:null,
              isFullTime:null,
              major:'',
              schoolName:''
            }
          ]
          this.updateItem.coachPersonageDetails = {}
          this.updateItem.coachRoster = {}
        }
        // setTimeout(() => {
        //   this.$refs.firstAddRules.clearValidate()
        // }, 0);
      },
      deep: true, 
      immediate: true,
    }
  }
}


</script>
<style scoped>
/deep/ .el-upload--picture-card{
  width: 80px;
  height: 80px;
}
/deep/ .el-upload{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.el-dialog__body{ 
  height: 350px !important;
}
.addWorkNumber{
  padding: 0 5px;
  border: 1px solid black;
  border-radius:50%;
  height: 40px;
   width: 40px;
   margin-left: 10px;
   text-align: center;
   font-size: large;
   cursor: pointer;
}
</style>